.bw-image {
  /* prevent to sync image on top */
  /* position: absolute;  */
  transition: 0.5s;
  display: block;
  /*   width: 100%;
  height: 100%;
  background-image: url("/public/assets/img/black.png");
  background-position: center;
  background-size: cover; */

  background-image: url("/public/assets/img/home/black.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}

.test {
  background-image: url("/public/assets/img/home/colorful.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  position: absolute;
}
.test_1 {
  background-image: url("/public/assets/img/home/black.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}

.colorful_phone {
  display: block;
  /* clip-path: polygon(42% 8%, 57% 8%, 100% 100%, 0% 100%);  center */
  /* clip-path: polygon(38% 6%, 55% 8%, 50% 100%, -50% 100%); left */
  /* clip-path: polygon(46% 8%, 62% 6%, 200% 100%, 60% 100%); right */

  position: absolute;
  transition: all 1s;
  z-index: 10;

  background-image: url("/public/assets/img/home/colorful.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  position: absolute;
}
.colorful_phone_animation {
  animation: colorful_phone_first 1s ease-in-out infinite,
    colorful_phone 4s ease-in-out infinite;
  animation-delay: 0s, 1s; /* Delay the second animation by 3 seconds */
  transition: 0.5s;
}

.black_phone {
  display: none;
  transition: 0.5s;
}

.black_finish_animation {
  animation: black_finish_animation 1s forwards ease-in-out;
  animation-delay: 1s;
  transition: 0.5s;
}

.home {
  position: relative;
  overflow: hidden;
  padding-bottom: 0;
  padding: 0;
}

.arrow {
  width: 100px;
}

/* colorful img */
.colorful {
  position: absolute;
  transition: all 1s;
  z-index: 10;

  background-image: url("/public/assets/img/home/colorful.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  position: absolute;
}

.colorful_animation_reset {
  animation: img_swing_first 3s ease-in-out infinite;
  transition: 0.3s;
}

.colorful_animation {
  animation: img_swing_first 1s ease-in-out infinite,
    img_swing 4s ease-in-out infinite;
  animation-delay: 0s, 1s; /* Delay the second animation by 3 seconds */
  transition: 0.3s;
}

/* colorful img */

/* avize */

.avize {
  position: absolute;
  z-index: 15;
  width: 180px;
  transform-origin: center top;
  animation: swing_center_reset 1s ease-in-out infinite,
    swing 4s ease-in-out infinite;
  animation-delay: 0s, 1s;
  transition: 0.3s;
}

.avize_center {
  animation: swing_center 3s ease-in-out infinite;
}

.avize_out {
  display: none;
}
/* avize */

/* scroll effect */
.zoomIn {
  transition: all 1.5s;
  filter: brightness(0.5);
  transform: scale(1.5);
}

.light_center_right {
  animation: colorful_center_right 1s alternate;
  animation-fill-mode: forwards;
}
.light_center_left {
  animation: colorful_center_left 1s alternate;
  animation-fill-mode: forwards;
}

.colorful_finish_animation {
  animation: 1s colorful_finish_animation forwards ease-in-out;
  transition: 2s;
}
/* .colorful_finish_animation_phone {
  animation: 1s colorful_finish_animation_phone forwards ease-in-out;
  transition: 2s;
} */

/* .colorful_phone_center_right {
  animation: colorful_phone_center_right 2s forwards ease-in-out;
}

.light_phone_center_right {
  animation: colorful_phone_center_right 1s alternate;
  animation-fill-mode: forwards;
}
.light_phone_center_left {
  animation: colorful_phone_center_left 1s alternate;
  animation-fill-mode: forwards;
}
 */
/* scroll effect */

/* Content */

.hidden {
  opacity: 0;
  transition: opacity 1s;
  transform: translateY(20px);
}

.show {
  opacity: 1;
  transform: translateY(0);
}

.regular_texts {
  display: none;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 50;

  text-align: center;
  width: 100%;
  transition: 0.5s;
}
.regular_texts h2 {
  font-weight: 800;
  font-size: 60px;
  color: white;

  opacity: 0;
}
.regular_texts button {
  font-weight: 800;
  font-size: 30px;
  color: white;
  opacity: 0;
}

.regular_texts_show {
  display: block;
}

.regular_texts_show h2:nth-child(1) {
  animation: fade_in 1s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}
.regular_texts_show h2:nth-child(2) {
  animation: fade_in 1s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
}
.regular_texts_show h2:nth-child(3) {
  animation: fade_in 1s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}
.regular_texts_show button {
  animation: fade_in 1s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}

.regular_texts_hide {
  animation: fade_out 1s ease-in-out;
  animation-fill-mode: forwards;
}

/* Content */

/* @media-screen responsive */

@media (min-width: 1070px) and (max-width: 1280px) {
  .colorful_animation {
    animation: img_swing_first_1560px 1s ease-in-out infinite,
      img_swing_1560px 4s ease-in-out infinite;
    animation-delay: 0s, 1s;
    transition: 0.3s;
  }
}
@media (min-width: 1070px) and (max-width: 1280px) and (min-height: 799px) and (max-height: 899px) {
  .colorful_animation {
    animation: img_swing_first_1560px 1s ease-in-out infinite,
      img_swing_1560px_height 4s ease-in-out infinite;
    animation-delay: 0s, 1s;
    transition: 0.3s;
  }
}
@media (min-width: 790px) and (max-width: 1070px) {
  .colorful_animation {
    animation: img_swing_first_790px 1s ease-in-out infinite,
      img_swing_790px 4s ease-in-out infinite;
    animation-delay: 0s, 1s;
    transition: 0.3s;
  }
}
@media (min-width: 790px) and (max-width: 1070px) and (max-height: 840px) and (min-height: 707px) {
  .colorful_animation {
    animation: img_swing_first_790px_height 1s ease-in-out infinite,
      img_swing_790px_height 4s ease-in-out infinite;
    animation-delay: 0s, 1s;
    transition: 0.3s;
  }
}
@media (min-width: 790px) and (max-width: 1070px) and (min-height: 600px) and (max-height: 707px) {
  .colorful_animation {
    animation: img_swing_first_790px_height_2 1s ease-in-out infinite,
      img_swing_790px_height_2 4s ease-in-out infinite;
    animation-delay: 0s, 1s;
    transition: 0.3s;
  }
}

@media screen and (min-width: 570px) and (max-width: 679px) {
  .avize {
    width: 180px;
  }
  .colorful_animation {
    animation: img_swing_first_679px 1s ease-in-out infinite,
      img_swing_679px 4s ease-in-out infinite;
    animation-delay: 0s, 1s; /* Delay the second animation by 3 seconds */
    transition: 0.3s;
  }
}

/* @media screen and (max-width: 570px) and (min-width: 418px) {
  .colorful_animation {
    animation: img_swing_first_570px 1s ease-in-out infinite,
      img_swing_570px 4s ease-in-out infinite;
    animation-delay: 0s, 1s;
    transition: 0.3s;
  }
} */

@media (max-width: 790px) and (min-width: 679px) {
  .avize {
    width: 180px;
  }
  .colorful_animation {
    animation: img_swing_first_850px 1s ease-in-out infinite,
      img_swing_850px 4s ease-in-out infinite;
    animation-delay: 0s, 1s; /* Delay the second animation by 3 seconds */
    transition: 0.3s;
  }
}
@media (max-width: 790px) and (min-width: 679px) and (min-height: 707px) and (max-height: 855px) {
  .colorful_animation {
    animation: img_swing_first_850px_height 1s ease-in-out infinite,
      img_swing_850px_height 4s ease-in-out infinite;
    animation-delay: 0s, 1s;
    transition: 0.3s;
  }
}
@media (max-width: 790px) and (min-width: 679px) and (min-height: 600px) and (max-height: 707px) {
  .colorful_animation {
    animation: img_swing_first_850px_height_2 1s ease-in-out infinite,
      img_swing_850px_height_2 4s ease-in-out infinite;
    animation-delay: 0s, 1s;
    transition: 0.3s;
  }
}
@media (min-height: 1154px) and (max-height: 1238px) {
  .avize {
    width: 200px;
  }
}
@media (min-height: 1238px) {
  .avize {
    width: 300px;
  }
}

@media screen and (max-width: 1200px) {
  .avize {
    animation: none;
  }
  .colorful {
    clip-path: polygon(48% 12%, 52% 12%, 200% 100%, -100% 100%);
    animation: none;
  }
  .colorful.zoomIn {
    clip-path: polygon(45% 18%, 55% 18%, 70% 100%, 30% 100%);
  }
  .regular_texts button {
    opacity: 1;
    animation: none;
  }
  .regular_texts {
    display: block;
  }
}
@media screen and (max-width: 570px) {
  .avize {
    width: 150px;
  }
  .colorful {
    clip-path: polygon(40% 12%, 60% 12%, 200% 100%, -100% 100%);
  }
  .colorful.zoomIn {
    clip-path: polygon(45% 18%, 55% 18%, 100% 100%, 0% 100%);
  }
  .colorful_finish_animation {
    animation: colorful_finish_animation_phone 1s alternate;
    animation-fill-mode: forwards;
  }
}

@media screen and (min-width: 570px) {
  .colorful_phone {
    display: none;
    animation: none;
  }
  .black_phone {
    display: none;
    animation: none;
  }
  .colorful_finish_animation {
    animation: colorful_finish_animation 1s alternate;
    animation-fill-mode: forwards;
  }
}

@media screen and (max-width: 418px) {
  /* .colorful_animation {
    animation: colorful_phone_first 1s ease-in-out infinite,
      colorful_phone 4s ease-in-out infinite;
    animation-delay: 0s, 1s; 
    transition: 0.5s;
  }

  .light_center_left {
    animation: colorful_center_left_phone 1s alternate;
    animation-fill-mode: forwards;
  }

  .colorful_finish_animation {
    animation: colorful_finish_animation_phone 1s alternate;
    animation-fill-mode: forwards;
  } */

  /* .bw-image {
    display: none;
  }
 */
  /*   .bw-tablet {
    display: none;
  }
 */

  .avize {
    width: 120px;
  }
  .regular_texts h2 {
    font-size: 40px;
  }
}
@media (max-width: 720px) and (min-width:480px) and (max-height: 570px) and (min-height: 480px) {
 .avize{
  width: 80px;
 }
}
@media (max-width: 840px) and (min-width:600px) and (max-height: 1112px) and (min-height: 960px) {
 .avize{
  width: 230px;
 }
}
@media (max-width: 1280px) and (min-width:1200px) and (max-height: 950px) and (min-height: 900px) {
  .colorful_animation {
    animation: img_swing_first 1s ease-in-out infinite,
      img_swing_2 4s ease-in-out infinite;
    animation-delay: 0s, 1s;
    transition: 0.5s;
  }
}
@media (max-width: 420px) and (max-height: 950px) and (min-height: 800px) {
  .colorful {
    clip-path: polygon(40% 9%, 60% 9%, 200% 100%, -100% 100%);
  }
}
@media (max-width: 1200px) and (min-width:1100px) and (max-height: 950px) and (min-height: 800px) {
   .colorful {
    clip-path: polygon(45% 15%, 55% 15%, 130% 100%, -30% 100%);
  }
}
@media (max-width: 1100px) and (min-width:900px) and (max-height: 800px) and (min-height: 700px) {
   .colorful {
    clip-path: polygon(45% 15%, 55% 15%, 130% 100%, -30% 100%);
  }
}
@media (max-width: 1100px) and (min-width:900px) and (max-height: 1200px) and (min-height: 1000px) {
   .colorful {
    clip-path: polygon(45% 12%, 55% 12%, 130% 100%, -30% 100%);
  }
}

@media (min-width: 2560px) and (min-height: 1440px) {
.avize {
  width: 10%;
}
.header-list {
  font-size: 30px;
}
}
@media (max-width: 418px) and (min-height: 707px) and (max-height: 740px) {
  /*   .colorful {
    animation: colorful_phone_first_height_2 1s ease-in-out infinite,
      colorful_phone_height_2 4s ease-in-out infinite;
    animation-delay: 0s, 1s;
    transition: 0.5s;
  } */
}

@media (max-width: 1550px) and (min-width: 1300px) and (min-height: 600px) and (max-height: 850px) {
  .colorful_animation {
    animation: img_swing_first_3 1s ease-in-out infinite,
      img_swing_3 4s ease-in-out infinite;
    animation-delay: 0s, 1s;
    transition: 0.5s;
  }
}

/* @media-screen responsive */
