#aboutSection {
  color: white;
  margin-left: 150px;
  margin-right: 150px;
}

#aboutSection h2 {
  margin-bottom: 25px;
}
#aboutSection p {
  font-size: 23px;
  margin-bottom: 30px;
}
.content h2 {
  font-family: montserrat_medium;
  font-size: 40px;
}
.about {
  overflow: hidden;
  font-family: montserrat_extraBold;
  transform: translateY(600px);
  animation: aboutSlideUp 2.5s ease;
  animation-fill-mode: forwards;
  font-size: 200px !important;
  color: transparent;
  -webkit-text-stroke: 0.8px;
  -webkit-text-stroke-color: white;
}

.about_1 {
  font-size: 200px;
  /* font-weight: 800; */
  color: transparent;
  -webkit-text-stroke: 0.8px;
  -webkit-text-stroke-color: white;
}
.about_2 {
  font-weight: 800;
  color: #fff;
  font-size: 200px;
}
.content {
  /* position: absolute; */
  /* bottom: -800px; */
  margin-right: 100px;
  margin-left: 120px;
  transform: translateY(590px);
  opacity: 0;
  animation: aboutContent 0.7s ease, aboutContentSlideUp 1s ease;
  animation-delay: 1s, 1.5s;
  animation-fill-mode: forwards;
}

@keyframes aboutTitleBeingVisible {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

/* @keyframes aboutSlideUp {
  0% {
    transform: translateY(600px);
  }
  100% {
    transform: translateY(10px);
  }
} */
@keyframes aboutSlideUp {
  0% {
    transform: translateY(600px) translateX(4000px);
  }
  50% {
    transform: translateY(600px) translateX(0px);
  }
  100% {
    transform: translateY(10px) translateX(0px);
  }
}
/* @keyframes aboutTitleBeingVisible_2 {
  0% {
    opacity: 0.2;
    left: -45%;
  }
  100% {
    opacity: 1;
    left: -45%;
  }
} */

/* @keyframes aboutSlideUp_2 {
  0% {
    transform: translateY(600px);
  }
  100% {
    transform: translateY(60px);
  }
} */
/* @keyframes aboutTitleBeingVisible_3 {
  0% {
    opacity: 0.2;
    left: -60%;
  }
  100% {
    opacity: 1;
    left: -60%;
  }
} */
/* @keyframes aboutTitleBeingVisible_4 {
  0% {
    opacity: 0.2;
    left: -50%;
  }
  100% {
    opacity: 1;
    left: -50%;
  }
} */

/* @keyframes aboutSlideUp_3 {
  0% {
    transform: translateY(600px);
  }
  100% {
    transform: translateY(76px);
  }
} */

@keyframes aboutContent {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes aboutContentSlideUp {
  0% {
    transform: translateY(590px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes aboutContentSlideUp_2 {
  0% {
    transform: translateY(590px);
  }
  100% {
    transform: translateY(38px);
  }
}

@keyframes aboutContentSlideUp_3 {
  0% {
    transform: translateY(590px);
  }
  100% {
    transform: translateY(56px);
  }
}

@media /* (min-width: 940px) and */ (max-width: 1070px) {
  .about {
    animation: /* aboutTitleBeingVisible 0.5s ease, */ aboutSlideUp 2.5s ease;
    /* animation-delay: 0s, 0.6s; */
    animation-fill-mode: forwards;
  }
  .content {
    opacity: 0;
    animation: aboutContent 0.7s ease, aboutContentSlideUp 1s ease;
    animation-delay: 1s, 1.5s;
    animation-fill-mode: forwards;
  }
}

@media (min-width: 300px) and (max-width: 546px) {
  .content {
    font-size: 16px !important;
  }
  #aboutSection {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .about {
    animation: /* aboutTitleBeingVisible 0.5s ease, */ aboutSlideUp 2.5s ease;
    animation-fill-mode: forwards;
  }
  .content {
    opacity: 0;
    animation: aboutContent 0.7s ease, aboutContentSlideUp 1s ease;
    animation-delay: 1s, 1.5s;
    animation-fill-mode: forwards;
  }
}

@media (max-width: 1070px) {
  #aboutSection {
    margin-left: 60px;
    margin-right: 60px;
  }
  .content p {
    font-size: 18px !important;
  }
  .content h2 {
    font-size: 24px !important;
    margin-bottom: 10px !important;
  }
}
@media (max-width: 1669px) {
  .content {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 416px) {
  .content h2 {
    font-size: 22px !important ;
  }
  .content p {
    font-size: 14px !important;
  }
}

/* photo section */

.ziyaretciler {
  transform: translateY(500px);
  animation: ziyaretciler 0.8s ease;
  opacity: 0;
  animation-delay: 1.6s;
  animation-fill-mode: forwards;
}
.grafikler {
  transform: translateY(500px);
  opacity: 0;
  animation: grafikler 0.8s ease;
  animation-delay: 1.7s;
  animation-fill-mode: forwards;
}
.grafikler_2 {
  transform: translateY(500px);
  opacity: 0;
  animation: grafikler_2 0.8s ease;
  animation-delay: 1.8s;
  animation-fill-mode: forwards;
}

.wrapper {
  /* margin-top: 80px; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.wrapper > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 30px;
}
/* @media (max-width: 688px) {
  .wrapper {
    display: block !important;
  }
  .wrapper :nth-child(2),
  .wrapper :nth-child(3) {
    margin-top: 10%;
  }
} */

@keyframes ziyaretciler {
  0% {
    transform: translateY(500px);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(5px);
    opacity: 1;
  }
}

@keyframes grafikler {
  0% {
    transform: translateY(500px);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(5px);
    opacity: 1;
  }
}

@keyframes grafikler_2 {
  0% {
    transform: translateY(500px);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(5px);
    opacity: 1;
  }
}

@keyframes ziyaretciler_second {
  0% {
    transform: translateY(500px);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(70px);
    opacity: 1;
  }
}

@keyframes grafikler_second {
  0% {
    transform: translateY(500px);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(130px);
    opacity: 1;
  }
}

@keyframes grafikler_2_second {
  0% {
    transform: translateY(500px);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(190px);
    opacity: 1;
  }
}

@media (max-width: 688px) {
  .wrapper {
    display: block !important;
  }
  /*   .wrapper :nth-child(2),
      .wrapper :nth-child(3) {
        margin-top: 10%;
      } */
  .ziyaretciler {
    animation: ziyaretciler_second 0.8s ease;
    opacity: 0;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
  }
  .grafikler {
    opacity: 0;
    animation: grafikler_second 0.8s ease;
    animation-delay: 1.6s;
    animation-fill-mode: forwards;
  }
  .grafikler_2 {
    opacity: 0;
    animation: grafikler_2_second 0.8s ease;
    animation-delay: 1.7s;
    animation-fill-mode: forwards;
  }
}
/* @media (min-width: 546px) and (max-width: 1023px) {
  .about {
    animation: aboutTitleBeingVisible 0.5s ease, aboutSlideUp_3 1s ease;
    animation-delay: 0s, 0.6s;
    animation-fill-mode: forwards;
  }
} */

@media (min-width: 1220px) and (max-width: 1670px) {
  .about_1 {
    font-size: 150px !important;
  }
  .about_2 {
    font-size: 150px !important;
  }
}

@media (min-width: 710px) and (max-width: 1204px) {
  .about_1 {
    font-size: 100px !important;
  }
  .about_2 {
    font-size: 100px !important;
  }
}
@media screen and (max-width: 430px) {
  .about_1 {
    font-size: 40px !important;
  }
  .about_2 {
    font-size: 40px !important;
  }
}
@media (min-width: 430px) and (max-width: 710px) {
  .about_1 {
    font-size: 60px !important;
  }
  .about_2 {
    font-size: 60px !important;
  }
}
/* photo section */
