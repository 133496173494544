:root {
  --primary: ##000 !important;
  --bs-primary-bg-subtle: ##000 !important;
  --bs-accordion-btn-focus-box-shadow: ##000 !important;
  --bs-primary-border-subtle: ##000 !important;
  --bs-accordion-btn-focus-border-color: ##000 !important;
  --bs-accordion-btn-focus-box-shadow: ##000 !important;
  --bs-body-bg: ##000 !important;
  --bs-accordion-btn-color: white !important;
  --bs-body-color: white !important;
  --accordion-color: white !important;
  --accordion-bg: ##000 !important;
  --accordion-border-color: ##000 !important;
  --accordion-border-radius: ##000 !important;
  --accordion-inner-border-radius: ##000 !important;
  --accordion-btn-color: ##000 !important;
  --accordion-btn-bg: ##000 !important;
  --accordion-btn-focus-box-shadow: ##000 !important;
  --accordion-active-color: ##000 !important;
  --accordion-active-bg: ##000 !important;
  --bs-accordion-btn-icon-width: 0rem !important;
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-btn-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}
.accordion-header {
  border-bottom: 1px solid white;
}

.accordion-button {
  color: #fff;
  /* display: contents; */
  flex-direction: column;
  align-items: start !important;
}

.accordion-button:focus {
  color: #fff;
  border-color: #000 !important;
  box-shadow: none !important;
  border-radius: 10px;
  transition: all 0.3s;
  background-color: #000 !important;
}

.startNow_button {
  font-size: 16px !important;
  background-color: transparent;
  color: #fff;
  padding: 9px;
  width: 160px;
  position: relative;
  overflow: hidden;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-top: 55px;
}

.startNow_button:hover {
  color: black;
  border: 1px solid #fff;
}
.startNow_button:hover:after {
  left: 60%;
  width: 90%;
  background-color: #fff;
  transform: translateX(0) scaleY(2) scaleX(2);
}
.startNow_button:hover:before {
  left: -60%;
  width: 130%;
  background-color: #fff;
  transform: translateX(0) scaleY(2) scaleX(1);
}
.startNow_button:focus {
}
.startNow_button:after {
  content: "";
  position: absolute;
  left: 100%;
  transform: translateX(0) scaleY(1) scaleX(0.8);
  top: 0;
  width: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}
.startNow_button:before {
  content: "";
  position: absolute;
  left: 0;
  transform: translateX(0) scaleY(1) scaleX(0.8);
  top: 0;
  width: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}
.startNow_button:active {
  color: #666;
}

.services_icon_div {
  background-color: white;
  border-radius: 50%;
  padding: 6px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

i {
  color: black;
}
.li_element {
  display: flex;
  align-items: baseline;
}
.accordion-item {
  border: none !important;
  background-color: var(--bs-body-bg);
  color: #fff;
  /* width: 90%; */
}

@media (max-width: 1628px) {
  .accordion-item {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.accordion-item:nth-child(5) {
  margin-bottom: 200px;
}
.accordion-text {
  height: 4rem;
  margin: 8%;
}
.accordion-img {
  width: 370px;
  height: 50vh;
  border-radius: 50px;
  text-align: center;
  /* position: absolute; */
  box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
}
.accordionSecond-img {
  width: 370px;
  height: 50vh;
  border-radius: 50px;
  text-align: center;
  box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
}
.accordion_item_logo {
  margin-bottom: 22px;
}
.hidden {
  display: none !important;
}
.accordion-collapse {
  animation: slideDown 0.9s ease-in-out;
  transform-origin: top;
  opacity: 1;
}
.accordion-context {
  margin-left: 90px;
  margin-top: 45px;
}
.accordionSecond-context {
  margin-right: 100px;
  margin-top: 20px;
}
.accordion-elements {
  margin-left: 10px;
}

.btn_second {
  margin-left: -300px;
  margin-top: 130px;
}
@media (min-width: 1400px) and (max-width: 1628px) {
  .btn_second {
    margin-left: -260px;
    /* margin-top: 105px; */
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .btn_second {
    margin-left: -445px;
    /* margin-top: 105px;  */
  }
}
@media (min-width: 993px) and (max-width: 1200px) {
  .btn_second {
    margin-left: -355px;
    /* margin-top: 105px; */
  }
}
@media (min-width: 770px) and (max-width: 993px) {
  .btn_second {
    margin-left: -260px;
    /* margin-top: 105px; */
  }
}

@media (min-width: 558px) and (max-width: 770px) {
  .btn_second {
    margin-left: -380px;
    /* margin-left: -530px; */
  }
}
@media (min-width: 515px) and (max-width: 558px) {
  .btn_second {
    margin-left: -354px;
    margin-top: 105px;
  }
}
@media (min-width: 500px) and (max-width: 515px) {
  .btn_second {
    margin-left: -338px;
    margin-top: 105px;
  }
}
@media (min-width: 450px) and (max-width: 473px) {
  .btn_second {
    margin-left: -294px;
    margin-top: 105px;
  }
}
@media (min-width: 400px) and (max-width: 450px) {
  .btn_second {
    /* margin-left: -260px; */
    margin-left: -240px;
    margin-top: 105px;
  }
}
@media (min-width: 380px) and (max-width: 400px) {
  .btn_second {
    margin-left: -225px;
    margin-top: 105px;
  }
}
@media (min-width: 350px) and (max-width: 380px) {
  .btn_second {
    margin-left: -200px;
    margin-top: 105px;
  }
}
@media (min-width: 300px) and (max-width: 350px) {
  .btn_second {
    margin-left: -160px;
    margin-top: 105px;
  }
}
@media (max-width: 450px) {
  .services_icon_div {
    width: 30px;
    height: 30px;
  }
}
.collapse.show .accordion-collapse {
  animation: slideDown 0.9s ease-in-out;
}
.accordion-button:not(.collapsed)::after {
  background-image: none !important;
  transform: none !important;
}
.accordion-button::after {
  flex-shrink: 0;
  /*  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width); */
  margin-left: auto;
  content: "";
  background-image: none;
  background-repeat: no-repeat;

  transition: none;
  color: #fff !important;
}

#nonVisible {
  opacity: 0;
  /* margin-top: 16%; */
  animation: beVisible 0.3s ease-in-out 2.3s;
  animation-fill-mode: forwards;
}
@keyframes slideDown {
  0% {
    transform: translateY(-20%);
    opacity: 0;
  }
  50% {
    /* transform: translateY(-50%); */
    opacity: 0px;
  }
  75% {
    /* transform: translateY(-20%); */
    opacity: 0px;
  }
  95% {
    /* transform: translateY(-10%); */
    opacity: 0.4px;
  }
  100% {
    transform: translateY(0%);
    opacity: 1px;
  }
}

@media (max-width: 1400px) {
  .accordion-element {
    width: 100%;
  }

  .accordion-elements {
    margin-top: 10px !important;
  }
  .accordion-img {
    width: 350px;
    height: 500px;
  }
  .accordion-context {
    margin-left: 60px !important;
  }
}

@media (max-width: 700px) {
  #nonVisible {
    margin-top: 5%;
  }
}
@media (max-width: 991px) {
  .accordion-context {
    display: table-row-group;
  }
}

@media (max-width: 882px) {
  .accordion-img {
    width: 280px;
    margin-left: 0 !important;
    height: 430px;
  }

  .accordion-body {
    padding: 0 !important   ;
  }
}

@media (max-width: 768px) {
  .accordion-body {
    flex-direction: column !important;
  }
  .accordionSecond-context,
  .accordion-context {
    margin-left: 0px !important;
    width: 100% !important;
  }
}
@media (max-width: 1200px) {

  .accordionSecond-context {
    margin-right: 35px;
  }
  .startNow_button2 {
    margin-left: 20px;
  }
}
@media (max-width: 881px) {
  .startNow_button2 {
    margin-top: 30px;
  }
}

/* services */

.services {
  overflow: hidden;
  font-family: montserrat_extraBold;
  transform: translateY(-600px) translateX(700px);
  /* transform: translateX(700px); */
  animation: services 2.5s ease;
  animation-fill-mode: forwards;
  font-size: 200px !important;
  color: transparent;
  -webkit-text-stroke: 0.8px;
  -webkit-text-stroke-color: white;
}
.about {
  /*   position: absolute;
  left: -52%;
  bottom: 5%; */
  font-family: montserrat_extraBold;
  transform: translateY(600px);
  animation: aboutTitleBeingVisible 0.5s ease, aboutSlideUp 1s ease;
  animation-delay: 0s, 0.6s;
  animation-fill-mode: forwards;
  font-size: 200px !important;
  color: transparent;
  -webkit-text-stroke: 0.8px;
  -webkit-text-stroke-color: white;
  /*  bottom: 5%;
    right: -300%; */
}
.services_1 {
  font-size: 200px !important;
  /* font-weight: 800; */
  color: transparent;
  -webkit-text-stroke: 0.8px;
  -webkit-text-stroke-color: white;
}
.services_2 {
  font-weight: 800;
  color: #fff;
  font-size: 200px !important;
}

@keyframes services {
  0% {
    transform: translateY(600px) translateX(4000px);
  }
  50% {
    transform: translateY(600px) translateX(0px);
  }
  100% {
    transform: translateY(10px) translateX(0px);
  }
}
@keyframes beVisible {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@media (min-width: 1293px) and (max-width: 1670px) {
  .services {
    animation: services 2.5s ease;
    animation-fill-mode: forwards;
  }
}
.accordion_item_title {
  font-weight: bold;
}
.services_icon {
  width: 55%;
}
@media (max-width: 759px) {
  .content_1 {
    width: 89% !important;
  }
}
@media (max-width: 600px) {
  .content_1 {
    width: 75%;
  }
  .accordion {
    transform: translateY(10px);
  }
  .services {
    /* animation: services_3 2.5s ease; */
    animation: services 2.5s ease;
    animation-fill-mode: forwards;
  }
  .accordion_item_title {
    font-size: 20px;
  }
  .content_1 {
    font-size: 14px;
  }
  .star_icon {
    width: 10%;
    position: absolute;
    top: 30%;
    right: 0;
  }
  .accordion_item_logo {
    width: 40px !important;
  }
  .accordion-title {
    font-size: 14px;
  }
  .ac_li_element {
    font-size: 14px;
  }
  .startNow_button {
    font-size: 14px;
    padding: 4px;
  }
  .bottom_div {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 768px) {
  .accordion-body_second {
    flex-wrap: wrap;
  }
  .accordionSecond-context {
    order: 2;
  }
  .bottom_div {
    order: 1;
  }
}
@media (min-width: 1220px) and (max-width: 1670px) {
  .services_1 {
    font-size: 150px !important;
  }
  .services_2 {
    font-size: 150px !important;
  }
}

@media (min-width: 710px) and (max-width: 1204px) {
  .services_1 {
    font-size: 100px !important;
  }
  .services_2 {
    font-size: 100px !important;
  }
}
@media screen and (max-width: 430px) {
  .services_1 {
    font-size: 40px !important;
  }
  .services_2 {
    font-size: 40px !important;
  }
}
@media (min-width: 430px) and (max-width: 710px) {
  .services_1 {
    font-size: 60px !important;
  }
  .services_2 {
    font-size: 60px !important;
  }
}
.accordion-button {
  padding-bottom: 0px !important;
}

@media (min-width: 360px) and (max-width: 374px) {
  .services {
    /* animation: services_4 2.5s ease; */
    animation: services 2.5s ease;
    animation-fill-mode: forwards;
  }
}

/* services */
