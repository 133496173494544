.contact {

  overflow: hidden;
  animation: contactSlideUp 2.5s ease-in-out;


  font-family: montserrat_extraBold;
  animation-fill-mode: forwards;
  transform: translateY(560px);
  color: transparent;
  -webkit-text-stroke: 0.8px;
  -webkit-text-stroke-color: white;
  position: relative;
  z-index: 95;
  margin-bottom: 90px;
}

.contact_1 {
  font-size: 200px;
  color: transparent;
  -webkit-text-stroke: 0.8px;
  -webkit-text-stroke-color: white;
}
.contact_2 {
  font-size: 200px;
  font-weight: 800;
  color: #fff;
}

.icon_div {
  height: 50px;
  width: 50px;
  border-radius: 3px;
  background-color: #fff;
  transform: rotate(45deg);
  position: relative;
  transition: all 0.5s;
}
.icon_div:hover {
  background-color: #bababa;
  transform: rotate(225deg);
  transition: all 0.5s;
}

.icon_div > img {
  position: absolute;
  transform: rotate(-45deg);
}
.icon_div:hover > img {
  filter: invert(1);
  transform: rotate(-225deg);
}
div > h5 {
  color: #909090;
}
div > a,
div > p {
  color: #fff;
}

.contact_section {
  transform: translateY(-100px);
  z-index: -1;
  margin-bottom: 5vh;
  position: relative;
}

.contact_icons {
  opacity: 0;
  margin-top: 190px;
  margin-bottom: 120px;
  animation: beVisible 0.5s forwards;
  animation-delay: 4.7s;
  overflow: visible;
}

.insta_logo {
  background-image: url("/public/assets/img/icon/instagram.png");
  width: 30px;
  height: 30px;
  margin-right: 30px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.linkedin_logo {
  background-image: url("/public/assets/img/icon/linkedin.png");
  width: 30px;
  height: 30px;
  margin-right: 30px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.x_logo {
  background-image: url("/public/assets/img/icon/twitter.png");
  width: 30px;
  height: 30px;
  margin-right: 30px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.facebook_logo {
  background-image: url("/public/assets/img/icon/facebook.png");
  width: 30px;
  height: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.linkedin_logo:hover {
  background-image: url("/public/assets/img/icon/linkedin(1).png");
  transition: all 0.3s ease-in-out;
  transform: translateY(-15px);
  width: 30px;
  height: 30px;
  overflow: visible;
}
.facebook_logo:hover {
  background-image: url("/public/assets/img/icon/facebook(2).png");
  transition: all 0.3s ease-in-out;
  transform: translateY(-15px);
  width: 30px;
  height: 30px;
  overflow: visible;
}
.x_logo:hover {
  background-image: url("/public/assets/img/icon/twitter(2).png");
  transition: all 0.3s ease-in-out;
  transform: translateY(-15px);
  width: 30px;
  height: 30px;
  overflow: visible;
}
.insta_logo:hover {
  background-image: url("/public/assets/img/icon/insta.png");
  transition: all 0.3s ease-in-out;
  transform: translateY(-15px);
  width: 30px;
  height: 30px;
  overflow: visible;
}

.contact_links {
  margin-left: 10%;
  margin-right: 10%;
  opacity: 0;
  margin-top: 500px;
  animation: beVisible 0.5s forwards;
  animation-delay: 4.7s;
}

@media (max-width: 888px) {
  .contact_links {
    flex-direction: column;
    align-items: start !important;
  }
  .contact_links > div {
    margin-bottom: 30px;
  }
}
@media (min-width: 610px) and (max-width: 888px) {
  .contact_links {
    margin-left: 30%;
  }
}

.form_top_input {
  opacity: 0;
  animation: foldLetter_opacity 1s forwards;
  animation-delay: 3s;
}

@media (max-width: 500px) {
  .contact_links {
    margin-top: 150% !important;
  }
}
@media (max-width: 1204px) {
  .form {
    top: 60px;
  }
}
@keyframes contactTitleBeingVisible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes contactSlideUp {
  0% {
    transform: translateY(600px) translateX(4000px);
  }
  50% {
    transform: translateY(600px) translateX(0px);
  }
  100% {
    transform: translateY(10px) translateX(0px);
  }
}
@keyframes beInvisible {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@keyframes beVisible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes beVisible_phone {
  0% {
    transform: translateY(-10%) scale(0.5);
    opacity: 0;
  }
  100% {
    transform: translateY(-10%) scale(0.5);
    opacity: 1;
  }
}

/*form */

@keyframes foldLetterScale {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes foldLetterScale_phone {
  0% {
    transform: translateY(-10%) scale(0.5);
  }
  100% {
    transform: translateY(-10%) scale(1);
  }
}
@keyframes foldLetter {
  0% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
@keyframes foldLetter_opacity {
  0% {
    opacity: 0;
    z-index: 4;
  }
  100% {
    opacity: 1;
    z-index: 4;
  }
}

.contact_form {
  color: #fff;
  background-image: url("/public/assets/img/paper.png") !important;
}

.form {
  position: absolute;
  width: 650px;
  opacity: 0;
  transform: translateY(-30%);
  animation: beVisible 0.2s forwards;
  animation-delay: 2.5s;
  z-index: 90;
}
@media (max-width: 1200px) {
  .contact {
    margin-bottom: 0px;
  }
}

.form-top {
  position: relative;
  padding: 20px;
  padding-top: 130px;
  color: #fff;
  text-align: center;
  transform: rotateX(180deg);
  transition: transform 1s;
  animation: foldLetter 0.7s ease;
  animation-delay: 3.5s;
  animation-fill-mode: forwards;
  transform-origin: bottom;
  box-shadow: 0 9px 9px -9px #161616;
  z-index: 10;
}

.form-part-2 {
  position: relative;
  opacity: 0;
  padding-bottom: 130px;
  z-index: 10;
}
.form_top_input h3 {
  opacity: 0;
  animation: beVisible 0.2s forwards;
  animation-delay: 3.7s;
  color: #dedede;
  font-weight: bold;
}
input {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #fff;
  width: 400px;
  opacity: 0;
  animation: beVisible 0.2s forwards;
  animation-delay: 3.7s;
  padding-top: 20px;
  padding-left: 10px;
  color: white;
}

.message {
  width: 400px;
}
input:focus {
  color: #fff;
}
.form-btn:active {
  color: #666;
}
.contact_link_icons h5 {
  font-weight: bolder;
}
.contact_link_icons a {
  font-weight: bold;
}

.form-btn {
  font-weight: bold;
  display: inline-block;
  transition: all 0.2s ease-in;
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 400px;
  background-color: transparent;
  border: 1px solid #909090;
  color: #909090;
  border-radius: 10px;
  padding: 5px;
  margin-top: 40px;
}

.form-btn:before {
  content: "";
  position: absolute;
  left: 0;
  transform: translateX(0) scaleY(1) scaleX(0.8);
  top: 0;
  width: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.form-btn:after {
  content: "";
  position: absolute;
  left: 100%;
  transform: translateX(0) scaleY(1) scaleX(0.8);
  top: 0;
  width: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.form-btn:hover {
  color: black;
  border: 1px solid #fff;
}

.form-btn:hover:before {
  left: -60%;
  width: 130%;
  background-color: #fff;
  transform: translateX(0) scaleY(2) scaleX(1);
}

.form-btn:hover:after {
  left: 60%;
  width: 90%;
  background-color: #fff;
  transform: translateX(0) scaleY(2) scaleX(2);
}

/* media */

@media (min-width: 1220px) and (max-width: 1670px) {
  .contact_1 {
    font-size: 150px !important;
  }
  .contact_2 {
    font-size: 150px !important;
  }
}

@media (min-width: 710px) and (max-width: 1204px) {
  .contact_1 {
    font-size: 100px !important;
  }
  .contact_2 {
    font-size: 100px !important;
  }
}
@media screen and (max-width: 430px) {
  .contact_1 {
    font-size: 40px !important;
  }
  .contact_2 {
    font-size: 40px !important;
  }
}
@media (min-width: 430px) and (max-width: 710px) {
  .contact_1 {
    font-size: 60px !important;
  }
  .contact_2 {
    font-size: 60px !important;
  }
}
/* media */

/*form */

@keyframes slideUpLetter {
  0% {
    transform: translateY(180%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes slideDownLetter {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(120%);
  }
}
@keyframes slideUpCover {
  0% {
    transform: translateY(360%) rotate(180deg);
  }
  100% {
    transform: translateY(0%) rotate(180deg);
  }
}
@keyframes slideDownCover {
  0% {
    transform: translateY(0%) rotate(0deg);
  }
  100% {
    transform: translateY(240%) rotate(0deg);
  }
}
@keyframes coverOpening {
  0% {
    transform: translateY(0%) rotateX(180deg);
  }
  100% {
    transform: translateY(0%) rotateX(0deg);
    z-index: 90;
  }
}

.letter_front {
  position: absolute;
  z-index: 100;
  transform: translateY(180%);
  animation: slideUpLetter 1s cubic-bezier(0.29, 0.1, 0.29, 1) forwards,
    slideDownLetter 1s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 1.8s,
    beInvisible 0.3s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 4s;
  animation-delay: 1.5s, 3.2s, 4.5s;
}
.letter_cover {
  position: absolute;
  z-index: 100;
  bottom: 100%;
  transform-origin: bottom;
  transform: translateY(360%) rotate(180deg);
  animation: slideUpCover 1s cubic-bezier(0.29, 0.1, 0.29, 1) forwards,
    coverOpening 0.7s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 1.3s,
    slideDownCover 1s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 2.5s,
    beInvisible 0.3s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 4.7s;
  animation-delay: 1.5s, 2.5s, 3.2s, 4.5s;
}

.letter_background {
  position: absolute;
  z-index: 90;
  transform: translateY(180%);
  animation: slideUpLetter 1s cubic-bezier(0.29, 0.1, 0.29, 1) forwards,
    slideDownLetter 1s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 1.8s,
    beInvisible 0.3s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 4s;
  animation-delay: 1.5s, 3.2s, 4.5s;
}

@media (max-width: 531px) {
  .form-btn {
    font-size: 9px;
    margin-top: 25px;
  }
  .form {
    width: 316px !important;


    animation: beVisible 0.5s forwards, foldLetterScale 0.6s forwards;
    animation-delay: 2s, 3.3s;
    transform: scale(0.5);
    padding-top: 76px;
  }
}

@keyframes slideUpLetter_tablet {
  0% {
    transform: translateY(220%);
  }
  100% {
    transform: translateY(50%);
  }
}
@keyframes slideDownLetter_tablet {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(180%);
  }
}
@keyframes slideUpCover_tablet {
  0% {
    transform: translateY(440%) rotate(180deg);
  }
  100% {
    transform: translateY(100%) rotate(180deg);
  }
}
@keyframes slideDownCover_tablet {
  0% {
    transform: translateY(100%) rotate(0deg);
  }
  100% {
    transform: translateY(360%) rotate(0deg);
  }
}
@keyframes coverOpening_tablet {
  0% {
    transform: translateY(100%) rotateX(180deg);
    transform-origin: bottom;
  }
  100% {
    transform: translateY(100%) rotateX(0deg);
    transform-origin: bottom;
    z-index: 90;
  }
}

@media (min-width: 537px) and (max-width: 880px) {
  .form-part-2 {
    padding-bottom: 75px;
  }
  .form-top {
    padding-top: 75px;
  }
  .form {
    width: 474px;
    padding-top: 76px;
  }
  .form_top_input h3 {
    font-size: 16px;
  }
  .message,
  .form-btn,
  input {
    width: 120%;
  }
  .letter_front {
    width: 530px;
    transform: translateY(220%);

    animation: slideUpLetter_tablet 1s cubic-bezier(0.29, 0.1, 0.29, 1) forwards,
      slideDownLetter_tablet 1s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 1.8s,
      beInvisible 0.3s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 4s;

    animation-delay: 1.5s, 3.2s, 4.5s;
  }
  .letter_cover {
    width: 530px;
    transform: translateY(440%) rotate(180deg);


    animation: slideUpCover_tablet 1s cubic-bezier(0.29, 0.1, 0.29, 1) forwards,
      coverOpening_tablet 0.7s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 1.3s,
      slideDownCover_tablet 1s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 2.5s,
      beInvisible 0.3s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 4.7s;


    animation-delay: 1.5s, 2.5s, 3.2s, 4.5s;
  }
  .letter_background {
    width: 530px;
    transform: translateY(220%);

    animation: slideUpLetter_tablet 1s cubic-bezier(0.29, 0.1, 0.29, 1) forwards,
      slideDownLetter_tablet 1s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 1.8s,
      beInvisible 0.3s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 4s;

    animation-delay: 1.5s, 3.2s, 4.5s;
  }
}

@keyframes slideUpLetter_phone {
  0% {
    transform: translateY(350%);
  }
  100% {
    transform: translateY(90%);
  }
}
@keyframes slideDownLetter_phone {
  0% {
    transform: translateY(90%);
  }
  100% {
    transform: translateY(290%);
  }
}
@keyframes slideUpCover_phone {
  0% {
    transform: translateY(700%) rotate(180deg);
  }
  100% {
    transform: translateY(180%) rotate(180deg);
  }
}
@keyframes slideDownCover_phone {
  0% {
    transform: translateY(180%) rotate(0deg);
  }
  100% {
    transform: translateY(580%) rotate(0deg);
  }
}
@keyframes coverOpening_phone {
  0% {
    transform: translateY(180%) rotateX(180deg);
    transform-origin: bottom;
  }
  100% {
    transform: translateY(180%) rotateX(0deg);
    transform-origin: bottom;
    z-index: 90;
  }
}

@media (max-width: 537px) {
  .form-part-2 {
    padding-bottom: 50px;
  }
  .form-top {
    padding-top: 50px;
    padding-bottom: 10px !important;
  }

  .form {
    width: 474px;
    animation: beVisible_phone 0.5s forwards,
      foldLetterScale_phone 0.6s forwards;
    animation-delay: 2.5s, 3.5s;
    transform: translateY(-10%) scale(0.5);
  }

  .form_top_input h3 {
    font-size: 14px;
  }
  .form_top_input input {
    font-size: 12px;
  }
  .message,
  .form-btn,
  input {
    width: 214px;
  }
  .letter_front {
    width: 350px;
    transform: translateY(350%);

    animation: slideUpLetter_phone 1s cubic-bezier(0.29, 0.1, 0.29, 1) forwards,
      slideDownLetter_phone 1s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 1.8s,
      beInvisible 0.3s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 4s;

    animation-delay: 1.5s, 3.2s, 4.5s;
  }
  .letter_cover {
    width: 350px;
    transform: translateY(700%) rotate(180deg);


    animation: slideUpCover_phone 1s cubic-bezier(0.29, 0.1, 0.29, 1) forwards,
      coverOpening_phone 0.7s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 1.3s,
      slideDownCover_phone 1s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 2.5s,
      beInvisible 0.3s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 4.7s;


    animation-delay: 1.5s, 2.5s, 3.2s, 4.5s;
  }
  .letter_background {
    width: 350px;
    transform: translateY(350%);

    animation: slideUpLetter_phone 1s cubic-bezier(0.29, 0.1, 0.29, 1) forwards,
      slideDownLetter_phone 1s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 1.8s,
      beInvisible 0.3s cubic-bezier(0.29, 0.1, 0.29, 1) forwards 4s;

    animation-delay: 1.5s, 3.2s, 4.5s;
  }
}
