.not_found_div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.background {
  background-image: url("/public/assets/img/home/black.png");
  filter: brightness(0.2) blur(2px);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}

.not_found {
  font-family: montserrat_900;
  font-size: 200px;
  color: transparent;
  -webkit-text-stroke: 1px;
  -webkit-text-stroke-color: white;
}

.notFound_text {
  /* font-family: montserrat_medium; */
  font-family: montserrat_extraBold;
  font-size: 50px;
}

@keyframes notFound {
  0% {
    color: transparent;
  }
  50% {
    color: white;
  }
  100% {
    color: transparent;
  }
}

h1 span:nth-child(0) {
  animation: notFound 0.5s ease-in-out 0s;
  /* notFound 1s ease-in-out 5s  infinite; */
  /* transition: all 0.4s; */
}

h1 span:nth-child(1) {
  animation: notFound 0.5s ease-in-out 1s;
  /* notFound .5s ease-in-out 6s infinite; */
  /* transition: all 0.4s; */
}

h1 span:nth-child(2) {
  animation: notFound 0.5s ease-in-out 2s;
  /* notFound 1s ease-in-out 7s infinite; */
  /* transition: all 0.4s; */
}

h1 span:nth-child(3) {
  animation: notFound 0.5s ease-in-out 3s;
  /* notFound 1s ease-in-out 8s infinite; */
  /* transition: all 0.4s; */
}

h1 span:nth-child(4) {
  animation: notFound 0.5s ease-in-out 4s;
  /* notFound 1s ease-in-out 9s infinite; */
}

@media (max-width: 690px) {
  .not_found {
    font-size: 100px;
  }
  .notFound_text{
    font-size: 30px;
  }
}

@media (max-width: 370px) {
  .not_found {
    font-size: 50px;
    
  }
  .notFound_text{
    font-size: 20px;
  }
}
