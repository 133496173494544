/* Header */
.me-6 {
  margin-right: 30px;
}
.header-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
  font-weight: bolder;
  transition: 0.3s;

  padding: 20px 20px 10px 20px;
  background-color: black;
}

.header-list ul li {
  border-bottom: 1px solid;
  padding-right: 20px;
}

.header-list ul li:hover {
  cursor: pointer;
  border-bottom: 1px solid white;
  color: white;
  transition: 0.3s;
}

.menu_phone {
  display: flex;
  justify-content: start;
  align-items: end;
  flex-direction: column;
  height: 100vh;
  width: 300px;

  background-color: #111111;
  position: fixed;
  /* transform: translateX(-300px);  */
  left: 100%;
  top: 0;

  color: #909090;
  transition: transform 0.5s;
}
.menu_phone_open {
  /* right: 0; */
  transform: translateX(-300px); /* Slide in from the right */
}
.menu_toggle_phone {
  flex-direction: column;

  gap: 30px;
  margin-top: 111px;
}

.menu_toggle_phone li {
  border: none !important;
  text-align: end;
}
.active_link {
  color: white;
}

.menu_icons {
  position: absolute;
  bottom: 50px;
  right: 50px;
}
.menu_icons li {
  border: none !important;
}

#checkbox {
  display: none;
}

.toggle {
  position: absolute;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
  transition-duration: 0.3s;
  z-index: 200;
  right: 20px;
  bottom: 20px;
}

.bars {
  width: 100%;
  height: 3px;
  background-color: white;
  border-radius: 5px;
  transition-duration: 0.3s;
}

#checkbox:checked + .toggle .bars {
  margin-left: 10px;
}

#checkbox:checked + .toggle #bar2 {
  transform: rotate(135deg);
  margin-left: 0;
  transform-origin: center;
  transition-duration: 0.3s;
}

#checkbox:checked + .toggle #bar1 {
  transform: rotate(45deg);
  transition-duration: 0.3s;
  transform-origin: left center;
}

#checkbox:checked + .toggle #bar3 {
  transform: rotate(-45deg);
  transition-duration: 0.3s;
  transform-origin: left center;
}

/* @media-screen responsive */

@media screen and (max-width: 769px) {
  .header_links {
    font-size: 12px;
    display: none !important;
  }
  .header_img img {
    width: 70%;
  }
  /*  .header_links {
    visibility: hidden;
  } */
  .header {
    justify-content: space-between !important;
  }
}
@media screen and (min-width: 770px) {
  .header_links_phone {
    display: none;
  }
  .menu_toggle {
    display: none;
  }
}

/* @media-screen responsive */

/* Header */

/* footer */

footer {
  background-color: #121212 !important;
}
.footer_subtitle {
  font-weight: bolder;
}
footer:not(.footer_subtitle) {
  font-family: montserrat_medium;
}
.footer {
  /* padding-top: 136px; */
  padding-top: 100px;
  padding-bottom: 100px;
  /* padding-bottom: 137px; */
}
.footer_elements_title {
  font-family: montserrat_900;
}
.footer_elements:not(.footer_elements_title) {
  color: #909090;
}
.footer_elements:nth-child(1) {
  margin-top: 90px;
}
.footer_elements > :nth-child(1),
:nth-child(7) {
  color: white;
  font-size: 25px;
}

.footer_elements li:not(:first-child) {
  font-size: 18px;
  color: #dedede;
}

.footer_logo_div {
  margin-bottom: 80px;
  margin-left: 30px;
}

@media (max-width: 420px) {
  .footer_contact_elements {
    display: none;
  }
}

@media (max-width: 1200px) {
  .footer_logo_div,
  .footer_contact_elements {
    margin-left: 0px !important;
  }
}
@media (max-width: 688px) {
  .icons {
    display: none !important;
  }
  .footer {
    padding-bottom: 60px;
  }
}
.icons {
  margin-top: 150px;
}
/* @media-screen responsive */

@media (max-width: 416px) {
  .footer_elements_title {
    font-size: 22px !important ;
  }
  .footer_elements {
    margin-bottom: 50px;
  }
  .mail_info {
    margin-left: -25px;
  }
}
@media screen and (max-width: 888px) {
  .footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .icons {
    display: inline-block;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
  .icons li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer_elements {
    padding: 0 !important;
  }
  .footer_elements:nth-child(1) {
    margin-top: 0;
  }
  /* .footer-servies-title{
    margin-top: 50px !important;
  } */
}

/* @media-screen responsive */

/* footer */

/* left side bar */

.sideLeft {
  position: fixed;
  top: 0;
  left: 0;
  /* width: 100px;  */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 100px;
}

.left_links {
  margin: 10px 0 10px 0;
}

/* left side bar */

/* right side bar */

.page_dots {
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  color: transparent;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.page_dots_filled {
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  color: transparent;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  background-color: #fff !important;
}
.page_dots:hover,
.page_dots:focus,
.page_dots:active {
  background-color: #fff !important;
  transition: all 0.5s ease-in-out;
}
.active_dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #fff;
  transition: all 0.5s ease-in-out;
}
/* .active_dot_about {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #fff;
  transition: all 0.5s ease-in-out;
}
.active_dot_services {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #fff;
  transition: all 0.5s ease-in-out;
}
.active_dot_contact {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #fff;
  transition: all 0.5s ease-in-out;
}
 */
/* .page_dots:focus + .page_dots_second {
  visibility: visible;
} */
.sideRight {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  padding-right: 100px;
  z-index: 100;
}

.right_links {
  /* margin: 10px; */
  /* margin-right: 10px; */
  /* margin: 10px 0 10px 0; */
  margin: 10px 0 10px 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

/* right side bar */

@media screen and (max-width: 669px) /* (max-width: 1669px) */ {
  .sideLeft {
    display: none;
  }
  .sideRight {
    display: none;
  }
}

