@keyframes black_finish_animation {
  0% {
    opacity: 1;
    scale: 1.5;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    scale: 1;
    transform: translateY(-100%);
  }
}

@keyframes img_swing_first {
  0% {
    clip-path: polygon(46% 15%, 54% 15%, 70% 100%, 30% 100%);
    /* clip-path: polygon(43% 10%, 53% 15%, 60% 100%, 0% 100%); */
  }
  /*  33% {
      clip-path: polygon(43% 15%, 52% 20%, 60% 100%, 0% 100%);
    } */
  100% {
    clip-path: polygon(45% 10%, 52% 15%, 60% 100%, 0% 100%);
  }
}
@keyframes img_swing_first_3 {
  0% {
    clip-path: polygon(46% 15%, 54% 15%, 70% 100%, 30% 100%);
  }
  100% {
    clip-path: polygon(44% 15%, 52% 17%, 60% 100%, 0% 100%);
  }
}

@keyframes img_swing_first_1560px {
  0% {
    /* clip-path: polygon(45% 15%, 55.5% 15%, 70% 100%, 30% 100%); */
    clip-path: polygon(44% 15%, 56.5% 15%, 70% 100%, 30% 100%);
  }
  100% {
    clip-path: polygon(42% 10%, 53% 15%, 60% 100%, 0% 100%);
  }
}
/* @keyframes img_swing_first_1560px_height {
    0% {
      clip-path: polygon(43% 16%, 53% 16%, 70% 100%, 30% 100%);
    }
    100% {
      clip-path: polygon(43% 17%, 53% 16%, 60% 100%, 0% 100%);
    }
  } */

@keyframes img_swing_first_850px {
  0% {
    clip-path: polygon(45% 8%, 56% 8%, 90% 100%, 10% 100%);
  }
  100% {
    clip-path: polygon(41% 8%, 53% 8%, 60% 100%, -10% 100%);
  }
}
@keyframes img_swing_first_850px_height {
  0% {
    clip-path: polygon(45% 10%, 56% 10%, 90% 100%, 10% 100%);
  }
  100% {
    clip-path: polygon(41% 10%, 53% 10%, 60% 100%, -10% 100%);
  }
}
@keyframes img_swing_first_850px_height_2 {
  0% {
    clip-path: polygon(45% 13%, 56% 13%, 90% 100%, 10% 100%);
  }
  100% {
    clip-path: polygon(41% 13%, 53% 13%, 60% 100%, -10% 100%);
  }
}
@keyframes img_swing_first_570px {
  0% {
    /* clip-path: polygon(40% 6.5%, 53% 6.5%, 60% 100%, -10% 100%); */
    /* clip-path: polygon(39% 6.5%, 53% 6.5%, 60% 100%, -30% 100%); */

    clip-path: polygon(44% 6.5%, 57% 6.5%, 90% 100%, 10% 100%);
    /* clip-path: polygon(42% 6.5%, 58% 6.5%, 90% 100%, 10% 100%); */
  }
  100% {
    clip-path: polygon(39% 6.5%, 53% 6.5%, 60% 100%, -30% 100%);
    /* clip-path: polygon(37% 6.5%, 54% 6.5%, 60% 100%, -30% 100%); */
  }
}
@keyframes img_swing_first_679px {
  0% {
    clip-path: polygon(44% 8%, 57% 8%, 100% 100%, 0% 100%);
  }
  100% {
    /* clip-path: polygon(40% 8%, 53% 8%, 60% 100%, 0% 100%);  */
    clip-path: polygon(40% 8%, 53% 8%, 60% 100%, -20% 100%);
  }
}
@keyframes img_swing_first_790px {
  0% {
    /* clip-path: polygon(40% 8%, 53% 8%, 60% 100%, 0% 100%); */
    clip-path: polygon(44% 13%, 57% 13%, 80% 100%, 20% 100%);
  }
  100% {
    /* clip-path: polygon(40% 8%, 53% 8%, 60% 100%, 0% 100%);  */
    clip-path: polygon(40% 13%, 53.5% 13%, 60% 100%, -10% 100%);
  }
}
@keyframes img_swing_first_790px_height {
  0% {
    /* clip-path: polygon(40% 8%, 53% 8%, 60% 100%, 0% 100%); */
    clip-path: polygon(44% 15%, 57% 15%, 80% 100%, 20% 100%);
  }
  100% {
    /* clip-path: polygon(40% 8%, 53% 8%, 60% 100%, 0% 100%);  */
    clip-path: polygon(40% 15%, 53.5% 15%, 60% 100%, -10% 100%);
  }
}
@keyframes img_swing_first_790px_height_2 {
  0% {
    /* clip-path: polygon(40% 8%, 53% 8%, 60% 100%, 0% 100%); */
    clip-path: polygon(44% 19%, 57% 19%, 80% 100%, 20% 100%);
  }
  100% {
    /* clip-path: polygon(40% 8%, 53% 8%, 60% 100%, 0% 100%);  */
    clip-path: polygon(40% 19%, 53.5% 19%, 60% 100%, -10% 100%);
  }
}
@keyframes img_swing_first_height {
  0% {
    /* clip-path: polygon(40% 8%, 53% 8%, 60% 100%, 0% 100%); */
    clip-path: polygon(44% 17%, 57% 17%, 80% 100%, 20% 100%);
  }
  100% {
    /* clip-path: polygon(40% 8%, 53% 8%, 60% 100%, 0% 100%);  */
    clip-path: polygon(40% 17%, 53.5% 17%, 60% 100%, -10% 100%);
  }
}

@keyframes img_swing {
  0% {
    clip-path: polygon(45% 10%, 52% 15%, 60% 100%, 0% 100%);
  }
  50% {
    clip-path: polygon(48% 15%, 55% 10%, 100% 100%, 40% 100%);
  }
  100% {
    clip-path: polygon(45% 10%, 52% 15%, 60% 100%, 0% 100%);
  }
}
@keyframes img_swing_2 {
  0% {
    clip-path: polygon(45% 10%, 52% 15%, 60% 100%, 0% 100%);
  }
  50% {
    clip-path: polygon(48% 15%, 55% 10%, 100% 100%, 40% 100%);
  }
  100% {
    clip-path: polygon(45% 10%, 52% 15%, 60% 100%, 0% 100%);
  }
}
@keyframes img_swing_3 {
  0% {
    clip-path: polygon(45% 13%, 52% 18%, 60% 100%, 0% 100%);
  }
  50% {
    clip-path: polygon(48% 18%, 55% 13%, 100% 100%, 30% 100%);
  }
  100% {
    clip-path: polygon(45% 13%, 52% 18%, 60% 100%, 0% 100%);
  }
}
@keyframes img_swing_1280px {
  0% {
    clip-path: polygon(45% 13%, 52% 18%, 60% 100%, 0% 100%);
  }
  50% {
    clip-path: polygon(48% 18%, 55% 13%, 100% 100%, 30% 100%);
  }
  100% {
    clip-path: polygon(45% 13%, 52% 18%, 60% 100%, 0% 100%);
  }
}

@keyframes img_swing_1560px {
  0% {
    clip-path: polygon(42% 10%, 53% 15%, 60% 100%, 0% 100%);
  }
  50% {
    clip-path: polygon(48% 15%, 58% 10%, 100% 100%, 40% 100%);
  }
  100% {
    clip-path: polygon(42% 10%, 53% 15%, 60% 100%, 0% 100%);
  }
}
@keyframes img_swing_1560px_height {
  0% {
    clip-path: polygon(43% 12%, 53% 15%, 60% 100%, 0% 100%);
  }
  50% {
    clip-path: polygon(48% 15%, 57% 12%, 100% 100%, 40% 100%);
  }
  100% {
    clip-path: polygon(43% 12%, 53% 15%, 60% 100%, 0% 100%);
  }
}

@keyframes img_swing_850px {
  0% {
    clip-path: polygon(41% 8%, 53% 8%, 60% 100%, -10% 100%);
    /* clip-path: polygon(41% 8%, 53% 8%, 60% 100%, 0% 100%); */
  }
  50% {
    clip-path: polygon(47% 8%, 60% 7.5%, 110% 100%, 40% 100%);
  }
  100% {
    clip-path: polygon(41% 8%, 53% 8%, 60% 100%, -10% 100%);
  }
}
/* @keyframes img_swing_850px_height {
  0% {
    clip-path: polygon(41% 9%, 53% 13%, 60% 100%, -10% 100%);
  }
  50% {
    clip-path: polygon(47% 13%, 60% 9%, 110% 100%, 40% 100%);
  }
  100% {
    clip-path: polygon(41% 9%, 53% 13%, 60% 100%, -10% 100%);
  }
} */
@keyframes img_swing_850px_height_2 {
  0% {
    /* clip-path: polygon(41% 12%, 53% 12%, 60% 100%, -10% 100%); */
    clip-path: polygon(41% 9%, 53% 13%, 60% 100%, -10% 100%);
  }
  50% {
    /* clip-path: polygon(47% 12%, 60% 12%, 110% 100%, 40% 100%); */
    clip-path: polygon(47% 13%, 60% 9%, 110% 100%, 40% 100%);
  }
  100% {
    /* clip-path: polygon(41% 12%, 53% 12%, 60% 100%, -10% 100%); */
    clip-path: polygon(41% 9%, 53% 13%, 60% 100%, -10% 100%);
  }
}
@keyframes img_swing_679px {
  0% {
    clip-path: polygon(40% 8%, 53% 8%, 60% 100%, -20% 100%);
  }
  50% {
    clip-path: polygon(47% 8%, 61% 7.5%, 110% 100%, 40% 100%);
  }
  100% {
    clip-path: polygon(40% 8%, 53% 8%, 60% 100%, -20% 100%);
  }
}
@keyframes img_swing_570px {
  0% {
    clip-path: polygon(39% 6.5%, 53% 6%, 60% 100%, -30% 100%);
  }
  50% {
    clip-path: polygon(47.5% 6%, 60% 6.5%, 140% 100%, 40% 100%);
  }
  100% {
    clip-path: polygon(39% 6.5%, 53% 6%, 60% 100%, -30% 100%);
  }
}
@keyframes img_swing_790px {
  0% {
    clip-path: polygon(40% 13%, 53.5% 13%, 60% 100%, -10% 100%);
  }
  50% {
    clip-path: polygon(47% 13%, 61% 13%, 110% 100%, 40% 100%);
  }
  100% {
    clip-path: polygon(40% 13%, 53.5% 13%, 60% 100%, -10% 100%);
  }
}
@keyframes img_swing_790px_height {
  0% {
    clip-path: polygon(40% 15%, 53.5% 15%, 60% 100%, -10% 100%);
  }
  50% {
    clip-path: polygon(47% 15%, 61% 15%, 110% 100%, 40% 100%);
  }
  100% {
    clip-path: polygon(40% 15%, 53.5% 15%, 60% 100%, -10% 100%);
  }
}
@keyframes img_swing_790px_height_2 {
  0% {
    clip-path: polygon(40% 19%, 53.5% 19%, 60% 100%, -10% 100%);
  }
  50% {
    clip-path: polygon(47% 19%, 61% 19%, 110% 100%, 40% 100%);
  }
  100% {
    clip-path: polygon(40% 19%, 53.5% 19%, 60% 100%, -10% 100%);
  }
}
@keyframes img_swing_height {
  0% {
    clip-path: polygon(40% 17%, 53.5% 17%, 60% 100%, -10% 100%);
  }
  50% {
    clip-path: polygon(47% 17%, 61% 17%, 110% 100%, 40% 100%);
  }
  100% {
    clip-path: polygon(40% 17%, 53.5% 17%, 60% 100%, -10% 100%);
  }
}
@keyframes img_swing_810px {
  0% {
    /* clip-path: polygon(45% 15%, 52% 20%, 60% 100%, 0% 100%); */
    clip-path: polygon(44% 6%, 52% 6%, 60% 100%, 0% 100%);
  }
  50% {
    /* clip-path: polygon(48% 20%, 55% 15%, 100% 100%, 40% 100%); */
    clip-path: polygon(45% 6%, 55% 6%, 100% 100%, 40% 100%);
  }
  100% {
    /* clip-path: polygon(45% 15%, 52% 20%, 60% 100%, 0% 100%); */
    clip-path: polygon(44% 6%, 52% 6%, 60% 100%, 0% 100%);
  }
}

@keyframes colorful_phone {
  0% {
    /* clip-path: polygon(37% 6%, 55% 8%, 50% 100%, -50% 100%); */
    clip-path: polygon(37% 6%, 55% 8%, 50% 100%, -50% 100%);
  }
  50% {
    /* clip-path: polygon(46% 8%, 63% 6%, 200% 100%, 60% 100%); */
    clip-path: polygon(46% 8%, 63% 6%, 200% 100%, 60% 100%);
  }
  100% {
    /* clip-path: polygon(37% 6%, 55% 8%, 50% 100%, -50% 100%); */
    clip-path: polygon(37% 6%, 55% 8%, 50% 100%, -50% 100%);
  }
}

@keyframes colorful_phone_height {
  0% {
    /* clip-path: polygon(37% 6%, 55% 8%, 50% 100%, -50% 100%); */
    clip-path: polygon(37% 9%, 55% 12%, 50% 100%, -50% 100%);
  }
  50% {
    /* clip-path: polygon(46% 8%, 63% 6%, 200% 100%, 60% 100%); */
    clip-path: polygon(46% 12%, 66% 9%, 200% 100%, 60% 100%);
  }
  100% {
    /* clip-path: polygon(37% 6%, 55% 8%, 50% 100%, -50% 100%); */
    clip-path: polygon(37% 9%, 55% 12%, 50% 100%, -50% 100%);
  }
}

@keyframes colorful_phone_height_2 {
  0% {
    /* clip-path: polygon(37% 6%, 55% 8%, 50% 100%, -50% 100%); */
    clip-path: polygon(39% 6%, 55% 10%, 50% 100%, -50% 100%);
  }
  50% {
    /* clip-path: polygon(46% 8%, 63% 6%, 200% 100%, 60% 100%); */
    clip-path: polygon(46% 10%, 62% 6%, 200% 100%, 60% 100%);
  }
  100% {
    /* clip-path: polygon(37% 6%, 55% 8%, 50% 100%, -50% 100%); */
    clip-path: polygon(39% 6%, 55% 10%, 50% 100%, -50% 100%);
  }
}

@keyframes colorful_phone_first {
  0% {
    clip-path: polygon(42% 6%, 57% 6%, 100% 100%, 0% 100%); /*  center */
    /* clip-path: polygon(37% 6%, 55% 8%, 50% 100%, -50% 100%);  */
  }
  /* 50% {
      clip-path: polygon(42% 8%, 57% 8%, 100% 100%, 0% 100%);
    } */
  100% {
    clip-path: polygon(37% 6%, 55% 8%, 50% 100%, -50% 100%); /* left */
  }
}
@keyframes colorful_phone_first_height {
  0% {
    clip-path: polygon(42% 9%, 57% 9%, 100% 100%, 0% 100%); /*  center */
    /* clip-path: polygon(37% 6%, 55% 8%, 50% 100%, -50% 100%);  */
  }
  /* 50% {
      clip-path: polygon(42% 8%, 57% 8%, 100% 100%, 0% 100%);
    } */
  100% {
    clip-path: polygon(37% 9%, 55% 12%, 50% 100%, -50% 100%); /* left */
  }
}
@keyframes swing_center_reset {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(15deg);
  }
}

@keyframes swing_center {
  0% {
    transform: rotate(15deg);
  }

  100% {
    transform: rotate(15deg);
  }
}
@keyframes swing {
  0% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(15deg);
  }
}
@keyframes swing_reset {
  0% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(15deg);
  }
}

@keyframes colorful_center_right {
  0% {
    clip-path: polygon(46% 20%, 57% 15%, 100% 100%, 40% 100%);
  }
  /*  50% {
      clip-path: polygon(43% 15%, 52% 20%, 60% 100%, 0% 100%);
    } */
  100% {
    clip-path: polygon(45% 18%, 55% 18%, 70% 100%, 30% 100%); /* center light */
  }
}
@keyframes colorful_center_left {
  0% {
    clip-path: polygon(44% 15%, 52% 20%, 60% 100%, 0% 100%);
  }
  /*  50% {
      clip-path: polygon(43% 15%, 52% 20%, 60% 100%, 0% 100%);
    } */
  100% {
    clip-path: polygon(45% 18%, 55% 18%, 70% 100%, 30% 100%); /* center light */
  }
}
@keyframes colorful_center_left_phone {
  0% {
    clip-path: polygon(44% 15%, 52% 15%, 60% 100%, -30% 100%);
  }
  /*  50% {
      clip-path: polygon(43% 15%, 52% 20%, 60% 100%, 0% 100%);
    } */
  100% {
    clip-path: polygon(45% 15%, 55% 15%, 100% 100%, 0% 100%); /* center light */
  }
}

@keyframes colorful_finish_animation {
  0% {
    clip-path: polygon(45% 18%, 55% 18%, 70% 100%, 30% 100%); /* center light */
  }
  100% {
    clip-path: polygon(50% 18%, 50% 18%, 50% 100%, 50% 100%);
  }
}
@keyframes colorful_finish_animation_phone {
  0% {
    clip-path: polygon(45% 18%, 55% 18%, 100% 100%, 0% 100%); /* center light */
  }
  100% {
    clip-path: polygon(50% 18%, 50% 18%, 50% 100%, 50% 100%);
  }
}

@keyframes colorful_phone_center_right {
  0% {
    clip-path: polygon(46% 8%, 62% 6%, 200% 100%, 60% 100%);
  }

  100% {
    clip-path: polygon(42% 8%, 57% 8%, 100% 100%, 0% 100%); /*  center */
  }
}
@keyframes colorful_phone_center_left {
  0% {
    clip-path: polygon(38% 6%, 55% 8%, 50% 100%, -50% 100%);
  }
  /*  50% {
      clip-path: polygon(43% 15%, 52% 20%, 60% 100%, 0% 100%);
    } */
  100% {
    clip-path: polygon(42% 8%, 57% 8%, 100% 100%, 0% 100%); /*  center */
  }
}

@keyframes fade_in {
  0% {
    top: 80%;
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    top: 50%;
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes fade_out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
