/*-------------------------
	Variable.CSS
-------------------------*/
:root {
  --first: #;
  --secondary: #;
  --third: #;
  --fourth: #;
}

.bg-first {
  background-color: var(--first);
}
.bg-secondary {
  background-color: var(--secondary);
}
.bg-third {
  background-color: var(--third);
}
.bg-fourth {
  background-color: var(--fourth);
}

.color-first {
  color: var(--first);
}

.color-secondary {
  color: var(--secondary);
}

.color-third {
  color: var(--third);
}

.color-fourth {
  color: var(--fourth);
}

.hv-100 {
  height: 100vh;
}
.hv-75 {
  height: 75vh;
}
.hv-50 {
  height: 50vh;
}
.hv-25 {
  height: 25vh;
}

/*--------------------------
    Global Styles
---------------------------*/

body {
  overflow-x: hidden !important;
  background-color: black;
  font-size: 20/24px;
  font-weight: 400;
  font-family: montserrat;
  color: #9b9b9b;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  box-sizing: border-box;
}

::selection {
  background-color: inherit;
  color: #ffffff;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

textarea {
  resize: none;
}

section {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}

img {
  max-width: 100%;
}

@font-face {
  font-family: montserrat;
  src: url(../src/assets/font/Montserrat-Regular.ttf);
}
@font-face {
  font-family: montserrat_900;
  src: url(../src/assets/font/Montserrat-Black.ttf);
}
@font-face {
  font-family: montserrat_medium;
  src: url(../src/assets/font/Montserrat-Medium.ttf);
}
@font-face {
  font-family: montserrat_semi;
  src: url(../src/assets/font/Montserrat-SemiBold.ttf);
}
@font-face {
  font-family: montserrat_extraBold;
  src: url(../src/assets/font/Montserrat-ExtraBold.ttf);
}

/*-------------------------
   RESET Default Styles
--------------------------*/
*,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li {
  margin-top: 0;
  padding-top: 0;
}

*,
:active,
:focus {
  outline: none;
}

ul,
ol {
  list-style: none;
}

button {
  border: none;
  padding: 0;
}

button,
button:focus,
.btn.focus,
.btn:focus,
.form-control,
.form-control:focus {
  outline: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

textarea {
  resize: none;
}

select {
  background-color: transparent;
}
